@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: comicsans;
  src: url(./fonts/ComicSansMS3.ttf);
}

html,
body {
  font-family: comicsans;
  max-width: 100%;
  overflow-x: hidden;
  @apply my-4;
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @apply gap-y-4 px-10;
}

h1 {
  @apply text-6xl;
}
